import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin } from "antd"; // Import Spin component from Ant Design

const PrivateRoute = ({ Component }) => {
  const { isAuthenticated } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []); 

  useEffect(() => {
  
    if (!isLoading && !isAuthenticated) {
      navigate("/signin");
    }
  }, [isLoading, isAuthenticated, navigate]);

  return (
    <div>
      {isAuthenticated ? (
        <Component />
      ) : (
        <div>
          <Spin
            style={{
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            spinning={isLoading}
            size="large"
          ></Spin>
        </div>
      )}
    </div>
  );
};

export default PrivateRoute;
