import axios from "axios";
import { BACKEND_URL } from "../../Url";
import {
  allUsersFailure,
  allUsersRequest,
  allUsersSuccess,
  allVideosFailure,
  allVideosRequest,
  allVideosSuccess,
  loadUserFailure,
  loadUserRequest,
  loadUserSuccess,
} from "../reducers/User";

const token = localStorage.getItem("token");

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  
  },
  withCredentials: true,
});


export const login = async (details) => {
  try {
    const { email, password } = details;
    const res = await axiosInstance.post("/admin/signin", {
      email,
      password,
      token
    });

    localStorage.setItem("token", res.data.token);

    return res.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const deleteVideos = async (details) => {
  try {
    const { videoId, index } = details;
    const res = await axiosInstance.post("/admin/deletevideo", {
      videoId,
      index,
      token,
    });

    return res.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const adminResetPassword = async (newPassword, id) => {
  try {
    const res = await axiosInstance.put(`/admin/resetpassword/${id}`, {
      password: newPassword, token
    });

    return res.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const adminForgotPassword = async (email) => {
  try {
    const res = await axiosInstance.post(`/admin/forgotpassword`, {
     email
    });

    return res.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const userResetPassword = async (newPassword, id) => {
  try {
    const res = await axiosInstance.put(`/user/resetpassword/${id}`, {
      password: newPassword,token
    });

    return res.data;
  } catch (e) {
    return e?.response?.data;
  }
};

export const createUser = async (options) => {
  try {
    const { firstName, lastName, email, password, role, status} = options;
    const response = await axiosInstance.post("/admin/createuser", {
      token,
      firstName,
      lastName,
      email,
      password,
      role,
      status,
    });

    return response.data;
  } catch (error) {
    console.error("Error in createUser:", error);
  }
};

export const editUser = async (options) => {
  try {
    const { firstName, lastName, email, password, role, id, status } = options;
    const response = await axiosInstance.put(`/admin/edituser/${id}`, {
      status,
      firstName,
      lastName,
      email,
      password,
      role,
      token
    });

    return response.data;
  } catch (error) {
    console.error("Error in register:", error);
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axiosInstance.put(`/admin/deleteuser/${id}`, {
      token
    });

    return response.data;
  } catch (error) {
    console.error("Error in delete User :", error);
  }
};
export const logout = async () => {
  try {
    // Clear token from local storage
    localStorage.removeItem("token");

    // Perform logout request if needed
    const res = await axiosInstance.get("/admin/logout");
    

    return res.data;
  } catch (error) {
    console.error("Error in logout:", error);
  }
};


export const loadProfile = () => async (dispatch) => {
  try {
    dispatch(loadUserRequest());
    const { data } = await axiosInstance.put("/admin/profile", {
      token 
    });

    dispatch(loadUserSuccess(data?.user));
  } catch (e) {
    console.log("the error is ", e);
    dispatch(loadUserFailure(e.message));
  }
};
export const loadProfile2 = (tok) => async (dispatch) => {
  try {
    dispatch(loadUserRequest());
    const { data } = await axiosInstance.put("/admin/profile", {
      token  : tok
    });

    dispatch(loadUserSuccess(data?.user));
  } catch (e) {
    console.log("the error is ", e);
    dispatch(loadUserFailure(e.message));
  }
};

export const allUsers = () => async (dispatch) => {
  try {
    dispatch(allUsersRequest());
    const { data } = await axiosInstance.put("/admin/allusers", {
      token
    });

    dispatch(allUsersSuccess(data?.users));
  } catch (e) {
    console.log("the error is ", e);
    dispatch(allUsersFailure(e.message));
  }
};
export const allVideosAction = () => async (dispatch) => {
  try {
    dispatch(allVideosRequest());
    const { data } = await axiosInstance.get("/admin/allvideos", {
      token
    });

    dispatch(allVideosSuccess(data?.videos));
  } catch (e) {
    console.log("the error is ", e);
    dispatch(allVideosFailure(e.message));
  }
};
