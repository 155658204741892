import { configureStore } from "@reduxjs/toolkit";
import { allUsers, allVideos, loadUser } from "./reducers/User";

const store = configureStore({
    reducer: {
        user: loadUser.reducer,
        allUsers: allUsers.reducer,
        allVideos: allVideos.reducer
    },
});

export default store