import React, { useState } from 'react'
import logo from '../../assets/Lumate-Logo-lt 1.svg'
import { Row, Col, message, Button } from 'antd';
import { loadProfile, loadProfile2, login } from '../../redux/actions/User';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const allUsers  = useSelector((state) => state.allUsers)
    const [email, setEmail] = useState("");
    
    const [password, setPassword] = useState("");

    const handleSubmit = async () => {
        const data = await login({
            email, password
        })
        if (data?.success) {
            message.success('Login Successful')
            await new Promise(resolve => setTimeout(resolve, 1000));
            await dispatch(loadProfile2(data?.token))
            navigate('/admin')
        } else {
            message.error(data?.message);
        }
    }
    const forgotPassword = () => {
        navigate("/adminforgotpassword")

     }



  return (
      <div>      
          <Row className='container'>
              <Col xs={22} sm={18} md={15} lg={12} xl={10} className='innerContainer'>
                  <div className='innerBox'>
                      <div className="logo">
                          <img src={logo} alt="Logo" />
                      </div>
                      <div className='changePassword'>
                          <h3>Sign In</h3>

                      </div>
                      <div className='changePasswordInput'>
                          <div className  = "signInComponent">
                              <p>Email</p>
                              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            
                          </div>
                          <div className  = "signInComponent">
                              <p>Password</p>
                              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                          </div>
                          
    
                      </div>
                      <div>
                          <p
                              style={{
                                  textAlign: "right",
                                  cursor: "pointer",
                                  fontWeight  : "bold"


                              }}
                              onClick={forgotPassword}
                          >Forgot Password?</p>

                      </div>
                      <Button
                          onClick={handleSubmit}
                          className='signinButton'>
                       Sign In
                      </Button>
                  </div>
              </Col>
          </Row>
    </div>
  )
}


export default SignIn