import React, { useState } from 'react';
import logo from '../../assets/Lumate-Logo-lt 1.svg';
import axios from "axios";
import { Row, Col, message, Button } from 'antd';
import { useParams } from 'react-router-dom';
import './ForgotPasswordAdmin.css'
import { useNavigate } from 'react-router-dom';
import { adminForgotPassword } from '../../redux/actions/User';


const ForgotPassworAdmin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState('');
  const sendLink = async () => {
    if (email) {
      setLoading(true);
      const res = await adminForgotPassword(email);
      console.log(res)
      if (res?.success) {
        message.success("Email has send successfully to your email.");
        navigate('/linksent')
      } else {

        message.error(res.message)
      }
      
    } else {
      message.error("Please enter email to send link")
    } setLoading(false);


  }

  return (
    <Row className='container'>
      <Col xs={22} sm={18} md={15} lg={12} xl={10} className='innerContainer'>
        <div className='innerBox'>
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <div className='changePassword'>
            <h3>Forgot Password</h3>
            <p>Please enter your email</p>
          </div>
          <div className='changePasswordInput2'>
            <div>
              <p>Email </p>
              <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
          </div>
          <div>
            <p
              style={{
                textAlign: "right",
                cursor: "pointer",
                fontWeight : "bold"
              }}
              onClick={() =>{navigate('/signin')}}
            >Back to login</p>

          </div>
          <Button className='button2' onClick={sendLink} loading={loading}>
            Send Link
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default ForgotPassworAdmin;
