import React, { useState } from 'react';
import { allUsers, createUser } from '../../redux/actions/User';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

const CreateUser = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        role: 'Student',
        status: 'active'
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
       
        const emptyFields = [];
        if (!formData.firstName) {
            emptyFields.push("First Name");
        }
        if (!formData.lastName) {
            emptyFields.push("Last Name");
        }
        if (!formData.email) {
            emptyFields.push("Email");
        }
        if (!formData.password) {
            emptyFields.push("Password");
        }
        if (!formData.role) {
            emptyFields.push("Role");
        }
        if (!formData.status) {
            emptyFields.push("Status");
        }

        if (emptyFields.length > 0) {
            const fieldsString = emptyFields.join(', ');
            message.error(`Please fill in the following fields: ${fieldsString}`);
            return; 
        }



        try {
            const data = await createUser(formData);
            if (data && data.success) {
                message.success("User created successfully");
                dispatch(allUsers());
            } else {
                message.error("Something went wrong");
            }
        } catch (error) {
            console.error("Error:", error);
            message.error("Something went wrong");
        }

        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            role: 'Student',
            status: 'active'
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <div className='editUser'>
                    <div className="signInComponent editInput">
                        <p>First Name</p>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                    </div>
                    <div className="signInComponent editInput">
                        <p>Last Name</p>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                    </div>
                </div>
                <div className='editUser'>
                    <div className="signInComponent editInput">
                        <p>Email id</p>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="signInComponent editInput">
                        <p>Password</p>
                        <input type="text" name="password" value={formData.password} onChange={handleChange} />
                    </div>
                </div>
                <div className="signInComponent editInput">
                    <p>Role</p>
                    <select name="role" value={formData.role} onChange={handleChange}>
                        <option value="Student">Guest</option>
                        <option value="Teacher">Host
                        </option>
                    </select>
                </div>
                <div className="signInComponent editInput">
                    <p>Status</p>
                    <select name="status" value={formData.status} onChange={handleChange}>
                        <option value="active">Active</option>
                        <option value="pending">Pending</option>
                    </select>
                </div>
                <button type="submit">Submit</button>
            </div>
        </form>
    );
};

export default CreateUser;
