import React, { useEffect } from 'react'
import Index from './routes'
import { useDispatch, useSelector } from 'react-redux'
import { allVideosAction, loadProfile } from './redux/actions/User';
import { useNavigate } from 'react-router-dom';
import { allVideos } from './redux/reducers/User';


const App = () => {
  const { isAuthenticated } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadUser = async () => {
    
    await dispatch(loadProfile());
    // navigate('/')
  }
  useEffect(() => {
     if (!isAuthenticated) {
  
       loadUser();
     }

  },[])
  useEffect(() => {
     if (isAuthenticated) {
        dispatch(allVideosAction())
     }
  }, [])
  
  return (
    <div>
     <Index/>
    </div>
  )
}

export default App