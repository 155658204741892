import { createSlice } from "@reduxjs/toolkit";
export const loadUser = createSlice({
  name: "post",
  initialState: {
    isAuthenticated: false,
    user: null,
  },
  reducers: {
    loadUserRequest: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    loadUserSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        isAuthenticated: true,
        user: action.payload,
      };
    },

    loadUserFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
      };
    },
  },
});



export const allUsers = createSlice({
  name: "allUsers",
  initialState: {
    loading: false,
    users : [],
  },
  reducers: {
    allUsersRequest: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    allUsersSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        users: action.payload,
      };
    },

    allUsersFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});


export const allVideos = createSlice({
  name: "allVideos",
  initialState: {
    loading: false,
    videos: [],
  },
  reducers: {
    allVideosRequest: (state, action) => {
      return {
        ...state,
        loading: true,
      };
    },

    allVideosSuccess: (state, action) => {
      return {
        ...state,
        loading: false,
        videos: action.payload,
      };
    },

    allVideosFailure: (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
  },
});


export  const { allUsersFailure, allUsersRequest, allUsersSuccess } = allUsers.actions;

export  const { allVideosSuccess, allVideosRequest, allVideosFailure } = allVideos.actions;

export const { loadUserFailure, loadUserSuccess, loadUserRequest } =
  loadUser.actions;
