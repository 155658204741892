import React, { useEffect,useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate, useParams, useLocation } from "react-router-dom";
import SignIn from "../pages/SignIn/SignIn";
import SignUp from "../pages/SignUp/SignUp";
import PrivateRoute from "./PrivateRoute";
import AdminPanel from "../pages/AdminPanel/AdminPanel";
import NotFound from "../pages/NotFound/NotFound";
import ForgotPassworAdmin from "../pages/ForgotPasswordAdmin/ForgotPassworAdmin";
import LinkSent from "../pages/LinkSent/LinkSent";
import PasswordResetDone from "../pages/ChangePasswordDone/PasswordResetDone";
import PasswordError from "../pages/ChangePasswordError/PasswordError";
import ForgotPasswordUser from "../pages/ForgotPasswordUser/ForgotPassword";
import AdminForgottonPasswordLink from "../pages/AdminForgottonPasswordLink/AdminForgottonPasswordLink";
import { useSelector } from "react-redux";


const Index = () => {
  const location = useLocation();
  const parmas = useParams();
  const { isAuthenticated } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (location?.pathname === "/") {
    if (!isLoading && !isAuthenticated) {
      navigate("/signin");
    } else {
      navigate("/admin")
    }
    }

  }, [isLoading, isAuthenticated, navigate]);


  return (
    <Routes>
      <Route path="/linksent" element={<LinkSent />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/adminforgotpassword" element={<ForgotPassworAdmin />} />
      <Route path="/forgotpassword/:id" element={<ForgotPasswordUser />} />
      <Route path="/admin/forgotpassword/:id" element={<AdminForgottonPasswordLink />} />
      <Route path="/passwordfailed" element={<PasswordError />} />
      <Route path="/passwordresetdone" element={<PasswordResetDone />} />
      {/* <Route path="/linksent" element={<LinkSent/>} /> */}
      <Route
        path="/admin"
        element={<PrivateRoute Component={AdminPanel} />}
        // element= {<AdminPanel/>}
        
      />

      <Route element={<NotFound />} />
    </Routes>
  );
};

export default Index;
