import React, { useState } from 'react';
import './EditUser.css';

const EditUser = ({ formData,setFormData, onSave }) => {


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

   
    const handleSubmit = (e) => {
        e.preventDefault();
        onSave(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <div className='editUser'>
                    <div className="signInComponent editInput">
                        <p>First Name</p>
                        <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
                    </div>
                    <div className="signInComponent editInput">
                        <p>Last Name</p>
                        <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
                    </div>
                </div>
                <div className='editUser'>
                    <div className="signInComponent editInput">
                        <p>Email id</p>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} />
                    </div>
                    <div className="signInComponent editInput">
                        <p>Password</p>
                        <input type="text" name="password" value={formData.password} onChange={handleChange} />
                    </div>
                </div>
                <div className="signInComponent editInput">
                    <p>Role</p>
                    <select name="role" value={formData.role} onChange={handleChange}>
                        <option value="Student">Guest</option>
                        <option value="Teacher">Host</option>
                    </select>
                </div>
                <button type="submit">Submit</button>
            </div>
        </form>
    );
};

export default EditUser;
