import React, { useState, useEffect } from 'react';
import './VideoPanel.css';
import { Pagination, message, Modal, Button, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { allVideosAction, deleteVideos } from '../../redux/actions/User';
import SearchBar from '../SearchBar/SearchBar';
import { MdDelete, MdDownload } from 'react-icons/md';

const VideoPanel = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState('new-to-old');
    const { videos } = useSelector((state) => state.allVideos || { videos: [] });
    const dispatch = useDispatch();
    const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 1168);

    const loadVideos = async () => {
        await dispatch(allVideosAction());
    };

    useEffect(() => {
        loadVideos();
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setSmallScreen(window.innerWidth <= 1168);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const indexOfLastContact = currentPage * itemsPerPage;
    const indexOfFirstContact = indexOfLastContact - itemsPerPage;

    const usersWithVideos = videos.filter(video => {
        const hasRecording = role => video.users && video.users[role] && video.users[role]?.recording;
        return hasRecording('student') || hasRecording('teacher') || hasRecording('both');
    }).filter(video => {
        const studentName = video?.users?.student?.user?.firstName || '';
        const teacherName = video?.users?.teacher?.user?.firstName || '';
        const bothName = video?.users?.both?.name || '';
        const query = searchQuery.toLowerCase();
        return studentName.toLowerCase().includes(query) ||
            teacherName.toLowerCase().includes(query) ||
            bothName.toLowerCase().includes(query);
    }).sort((a, b) => {
        if (sortOrder === 'new-to-old') {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else {
            return new Date(a.createdAt) - new Date(b.createdAt);
        }
    });

    const currentContacts = usersWithVideos.slice(indexOfFirstContact, indexOfLastContact);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery, itemsPerPage, sortOrder]);

    const handlePaginationChange = (page) => {
        setCurrentPage(page);
    };

    const truncateText = (text, maxWords) => {
        if (text?.length <= maxWords) {
            return text;
        } else {
            return text?.substring(0, maxWords) + '...';
        }
    };

    const downloadVideo = (videoUrl) => {
        window.open(videoUrl, '_blank');
    };

    const deleteVideo = (userId, role) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this video?',
            content: 'This action cannot be undone.',
            async onOk() {
                const data = await deleteVideos({ videoId: userId, index: role });

                if (data.success) {
                    message.success("Video deleted successfully");
                    loadVideos();
                } else {
                    message.error("Something went wrong");
                }
            },
        });
    };

    const renderVideoActions = (video, role) => (
        <div style={{
            display: 'flex',
            justifyContent: "space-between"
        }}>
            <Tooltip title={video?.users?.[role]?.recording}>
                <div className='contactItemSub contactItemHeight'>{truncateText(video?.users?.[role]?.recording, 8)}</div>
            </Tooltip>
            {smallScreen && (
                <div style={{ display: 'flex' }}>
                    <Button className='editButton downButton' onClick={() => downloadVideo(video?.users?.[role]?.recording)} disabled={!video?.users?.[role]?.recording} icon={<MdDownload />} />
                    <Button icon={<MdDelete />} className='deleteButton' onClick={() => deleteVideo(video._id, role)} disabled={!video?.users?.[role]?.recording} />
                </div>
            )}
        </div>
    );

    const renderDownloadButtons = (video) => (
        <div className='contactItem1 contactItemButton buttonz'>
            {/* <Button className='editButton downButton' onClick={() => downloadVideo(video?.users?.student?.recording)} disabled={!video?.users?.student?.recording}>Download</Button> */}
            <Button className='editButton downButton' onClick={() => downloadVideo(video?.users?.teacher?.recording)} disabled={!video?.users?.teacher?.recording}>Download</Button>
            <Button className='editButton downButton' onClick={() => downloadVideo(video?.users?.both?.recording)} disabled={!video?.users?.both?.recording}>Download</Button>
        </div>
    );

    const renderDeleteButtons = (video) => (
        <div className='contactItem1 contactItemButton buttonz'>
            {/* <Button className='deleteButton' onClick={() => deleteVideo(video._id, 'student')} disabled={!video?.users?.student?.recording}>Delete</Button> */}
            <Button className='deleteButton' onClick={() => deleteVideo(video._id, 'teacher')} disabled={!video?.users?.teacher?.recording}>Delete</Button>
            <Button className='deleteButton' onClick={() => deleteVideo(video._id, 'both')} disabled={!video?.users?.both?.recording}>Delete</Button>
        </div>
    );

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page whenever items per page changes
    };

    return (
        <div className='ContactDetails'>
            <div
                style={{
                    height: "100%",
                    display: 'flex',
                    justifyContent: "space-between",
                    flexDirection: "column"
                }}
            
            >

            
            <div className='headingNav'>
                <h3>Manage Recordings</h3>
                <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                <div className="sortingContainer">
                    <select
                   style ={{ 
                          backgroundColor
                                : "#18203D",
                            color: "white",
                            height: "34px",
                            border: "0px",
                            borderRadius: "5px",
                             margin: "0 2px "
                            
                        }}
                        className='sortingTechnique' value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
                        <option value="new-to-old">New to Old</option>
                        <option value="old-to-new">Old to New</option>
                    </select>
                    <select
                        style={{
                            backgroundColor
                                : "#18203D",
                            color: "white",
                            height: "34px",
                            border: "0px",
                            borderRadius: "5px",
                            margin: "0 2px "

                        }}
                    
                        value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        <option value={10}>10</option>
                        <option value={15}>15</option>
                        <option value={20}>25</option>
                    </select>
                </div>
            </div>

            <div className={!smallScreen ? 'contactNavbar' : "contactNavSmallBar"}>
                <div style={{ justifyContent: "center" }} className="contactItem">Session id</div>
                <div className="contactItem">Camera View</div>
                <div className="contactItem">Name</div>
                <div className="contactItem">Session Recording</div>
                {!smallScreen && <div style={{ justifyContent: "center" }} className="contactItem">Date</div>}
                {!smallScreen && <div style={{ justifyContent: "center" }} className="contactItem">Download</div>}
                {!smallScreen && <div style={{ justifyContent: "center" }} className="contactItem">Delete</div>}
            </div>

            <div className='contactsList' style={{ overflowY: 'scroll' }}>
                {currentContacts.map((video, index) => (
                    <div className={`${!smallScreen ? 'contactDetailVideo' : 'contactNavSmall'} ${index % 2 !== 0 ? 'changeBackgroundColor' : ''}`} key={index}>
                        <div className='contactItem1' style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>{index + 1}</div>
                        <div className='contactItem1 contactItemButton'>
                            {/* <Tooltip title="Student">
                                <div className='contactItemSub contactItemHeight'>Student</div>
                            </Tooltip> */}
                            <Tooltip title="Teacher">
                                <div className='contactItemSub contactItemHeight'>First View</div>
                            </Tooltip>
                            <Tooltip title="Third View">
                                <div className='contactItemSub contactItemHeight'>Third View</div>
                            </Tooltip>
                        </div>
                        <div className='contactItem1 contactItemButton'>
                            {/* <Tooltip title={video?.users?.student?.user?.firstName}>
                                <div className='contactItemSub contactItemHeight'>
                                    {truncateText(video?.users?.student?.user?.firstName, 10)}
                                </div>
                            </Tooltip> */}
                            <Tooltip title={video.users.teacher?.user?.firstName}>
                                <div className='contactItemSub contactItemHeight'>
                                    {truncateText(video.users.teacher?.user?.firstName, 10)}
                                </div>
                            </Tooltip>
                            <Tooltip title={video?.users?.both?.name}>
                                <div className='contactItemSub contactItemHeight'>
                                    {truncateText(video?.users?.both?.name, 10)}
                                </div>
                            </Tooltip>
                        </div>
                        <div className='contactItem1 contactItemButton'>
                            {/* {renderVideoActions(video, 'student')} */}
                            {renderVideoActions(video, 'teacher')}
                            {renderVideoActions(video, 'both')}
                        </div>
                        <div style={{ textAlign: "center" }} className='contactItem1'>{new Date(video.createdAt).toLocaleDateString()}</div>
                        {!smallScreen && renderDownloadButtons(video)}
                        {!smallScreen && renderDeleteButtons(video)}
                    </div>
                ))}
            </div>
            <div className='adminPagination'>
                <div className='paginationDetail'>
                    Showing {indexOfFirstContact + 1} to {Math.min(indexOfLastContact, usersWithVideos.length)} out of {usersWithVideos.length} entries
                </div>
                <div className="paginationButtons">
                    <Pagination
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={usersWithVideos.length}
                        onChange={handlePaginationChange}
                    />
                </div>
                </div>
            </div>
        </div>
    );
};

export default VideoPanel;
