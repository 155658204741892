import React, { useEffect, useState } from 'react'
import './SearchBar.css'
import { CiSearch } from 'react-icons/ci';

const SearchBar = ({
  searchQuery,setSearchQuery
}) => {
  const [smallScreen, setSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {

      if (window.innerWidth <= 1168) {
        setSmallScreen(false);
      } else {
        setSmallScreen(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); 


  return (
    <div className={smallScreen ? "searchBar" : "searchBar2"}>
        <CiSearch className="menuIcon2" />   
      <input
        className='searchBarInput'
        type="text"
        placeholder="Search by email or name"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  )
}

export default SearchBar