import React, { useState, useEffect } from 'react';
import { Layout, Menu, Dropdown, Modal } from 'antd';
import { IoPersonOutline, IoVideocamOutline } from 'react-icons/io5';


import ContactDetails from '../ContactDetails/ContactDetails';
import VideoPanel from '../VideoPanel/VideoPanel';
import { CiMenuBurger } from 'react-icons/ci';
import './Sidebar.css'
import { ImSwitch } from 'react-icons/im';
import { useDispatch, useSelector } from 'react-redux';
import { loadProfile, logout } from '../../redux/actions/User';
import { useNavigate } from 'react-router-dom';
const { Sider, Content } = Layout;

const SideBar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState('1');
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { user } = useSelector((state) => state.user);
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1168);
      if (window.innerWidth <= 1168) {
        setSidebarVisible(false);
      } else {
        setSidebarVisible(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleMenuSelect = ({ key }) => {
    setSelectedMenuItem(key);
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case '1':
        return <ContactDetails />;
      case '2':
        return <VideoPanel />;
      
      default:
        return null;
    }
  };

  const menu = (
    <Menu
      selectedKeys={[selectedMenuItem]}
      onSelect={handleMenuSelect}
      defaultOpenKeys={['sub1']}
      style={{ borderRight: 0, height: '100vh' }}
    >
      <Menu.Item key="1"
        onClick={ () => {isSmallScreen ? setSidebarVisible(false) : setSidebarVisible(sidebarVisible)}}
      >
        <div className="menu"
        >
          <IoPersonOutline className="icon" /> Manage Users
        </div>
      </Menu.Item>
      <Menu.Item key="2"
        onClick={() => { isSmallScreen ? setSidebarVisible(false) : setSidebarVisible(sidebarVisible) }}
      >
        <div className="menu">
          <IoVideocamOutline className="icon" /> Manage Recordings
        </div>
      </Menu.Item>
    </Menu>
  );
  const logoutt = async () => {
    await logout();
    await dispatch(loadProfile());
    setLogoutModalVisible(false);
    navigate('/signin')

  };

  return (
    <Layout style={{ height: '90vh' }}>
      
      <CiMenuBurger
        className={!isSmallScreen ? "toggleButton" : "toggleButton2"}
      
        onClick={() => setSidebarVisible(!sidebarVisible)} />
      {/* {isSmallScreen ? (
        <Dropdown overlay={menu} trigger={['click']}>
          <div className="small-screen-menu">
            <CiMenuBurger className={!isSmallScreen ? "toggleButton" : "toggleButton2"} onClick={() => setSidebarVisible(!sidebarVisible)} />
          </div>
        </Dropdown>
      ) : ( */}
        <Sider
          width={250}
        // className="site-layout-background"
        className={isSmallScreen ? "siderNav" : ""}
          style={{ display: sidebarVisible ? 'block' : 'none' }}
        >
        {menu}
        <div className="NavbarRight userDetails">
          {
            isSmallScreen &&

            <div className="switch">
              <ImSwitch onClick={() => setLogoutModalVisible(true)} className="imSwitch" />
            </div>
          }
          {
            isSmallScreen &&
            <div>
              <h3>{user?.name} </h3>
              <p>{user?.email}</p>
            </div>
          }
        </div>

        <Modal
          title="Logout"
          visible={logoutModalVisible}
          onOk={logoutt}
          onCancel={() => setLogoutModalVisible(false)}
          okText="Logout"
          cancelText="Cancel"
        >
          <p>Are you sure you want to logout?</p>
        </Modal>
        </Sider>
      {/* )} */}
      <Layout>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
          }}
        >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

export default SideBar;
