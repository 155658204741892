import React from 'react'
import logo from '../../assets/Lumate-Logo-lt 1.svg'
import { CalendarCheck, CheckCircleFill } from 'antd-icons';

import { Row, Col } from 'antd';
import { MdOutlineCheckCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';



const LinkSent = () => {
    const navigate = useNavigate();
    return (
        <Row className='container'>
            <Col
                xs={22} sm={18} md={15} lg={12} xl={10}

                className='innerContainer' >
                <div
                    className='innerBox'

                >
                    <div className="logo">
                        <img src={logo} />
                    </div>
                    <div className='checkCircle'>
                        <MdOutlineCheckCircle style={{ color: '#00CB39' }} />
                    </div>
                    <div className='changePassword'>
                        <h3>Link Sent</h3>
                        <p>The link has been sent to your email.</p>
                    </div>
                    <div>
                        <p
                            style={{
                                textAlign: "center",
                                cursor: "pointer",
                                fontWeight: "bold",
                                marginTop : "20px"
                            }}
                            onClick={() => { navigate('/signin') }}
                        >Back to login</p>

                    </div>

                </div>
            </Col>

        </Row>
    )
}

export default LinkSent