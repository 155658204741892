import { Modal, Pagination, Spin, Tooltip, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { allUsers, deleteUser, editUser } from '../../redux/actions/User';
import CreateUser from '../CreateUser/CreateUser';
import EditUser from '../EditUser/EditUser';
import SearchBar from '../SearchBar/SearchBar';
import './ContactDetails.css';

const ContactDetails = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
    const [userToDelete, setUserToDelete] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [smallScreen, setSmallScreen] = useState(false);
    const [formData, setFormData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState('new-to-old');
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.allUsers);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredUsers, setFilteredUsers] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1168) {
                setSmallScreen(false);
            } else {
                setSmallScreen(true);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setFormData({
            firstName: selectedUser?.firstName,
            lastName: selectedUser?.lastName,
            email: selectedUser?.email,
            password: selectedUser?.password,
            role: selectedUser?.role,
            id: selectedUser?._id,
            createdAt: selectedUser?.createdAt,
        });
    }, [selectedUser]);

    useEffect(() => {
        dispatch(allUsers());
    }, [dispatch]);

    useEffect(() => {
        const filtered = users.filter(user =>
            user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (user.firstName && user.firstName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (user.lastName && user.lastName.toLowerCase().includes(searchQuery.toLowerCase()))
        );

        if (sortOrder === 'new-to-old') {
            filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        } else if (sortOrder === 'old-to-new') {
            filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        }

        setFilteredUsers(filtered);
    }, [searchQuery, users, sortOrder]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleEditClick = (user) => {
        setSelectedUser(user);
        setIsEditModalVisible(true);
    };

    const handleDeleteClick = (user) => {
        setUserToDelete(user);
        setIsDeleteModalVisible(true);
    };

    const handleAddUserClick = () => {
        setIsAddUserModalVisible(true);
    };

    const handleModalCancel = () => {
        setSelectedUser(null);
        setIsEditModalVisible(false);
        setIsDeleteModalVisible(false);
        setIsAddUserModalVisible(false);
    };

    const handleEditUser = async (updatedUser) => {
        const data = await editUser(updatedUser);
        if (data?.success) {
            setIsEditModalVisible(false);
            await message.success("User updated successfully.");
            setSelectedUser(null);
            dispatch(allUsers());
        } else {
            message.error("User not updated");
        }
    };

    const changeStatus = async (status, id) => {
        setIsLoading(true)

        const data = await editUser({ status, id });
        if (data?.success) {
            await message.success("User updated successfully.");
            await dispatch(allUsers());
            setIsLoading(false);
        } else {
            message.error("User not updated");
        }
        setIsLoading(false);
    };

    const handleDeleteUser = async () => {
        const data = await deleteUser(userToDelete?._id);
        setIsDeleteModalVisible(false);
        setUserToDelete(null);
        if (data.success) {
            message.success("User deleted successfully");
            dispatch(allUsers());
        } else {
            message.error("User not deleted");
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(1); // Reset to first page whenever items per page changes
    };

    const indexOfLastContact = currentPage * itemsPerPage;
    const indexOfFirstContact = indexOfLastContact - itemsPerPage;
    const currentContacts = filteredUsers.slice(indexOfFirstContact, indexOfLastContact);

    return (
        <div className='ContactDetails'>
            <div
                style={{
                    height: "100%",
                    display: 'flex',
                    justifyContent: "space-between",
                    flexDirection : "column"
            }}
            >

           
            <div className='headingNav'>
                <h3
                    style={!smallScreen ? {
                        fontSize : "15px"
                    
                } : {}}
                >Manage Users</h3>
                <div
                    style={{
                        display: "flex",
                        justifyContent: 'center',
                        alignItems : "center"
                    }}
                
                    className="sortingContainer">
                    <select value={sortOrder}
                        style={{
                            backgroundColor
                                : "#18203D",
                            color: "white",
                            height: "34px",
                            border: "0px",
                            borderRadius: "5px",
                             margin: "0 2px "
                            
                        }}
                        
                        onChange={(e) => setSortOrder(e.target.value)}>
                        <option value="new-to-old">
                            Sort by: 
                            New to Old</option>
                        <option value="old-to-new">
                            
                            Sort by : Old to New</option>
                    </select>
                    <select value={itemsPerPage}
                        style={{
                            backgroundColor
                                : "#18203D",
                            color: "white",
                            height: "34px",
                            border: "0px",
                            borderRadius: "5px",
                            padding: "2px",
                            margin : "0 2px "

                        }}
                    
                        onChange={handleItemsPerPageChange}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                    </select>
                    <button
                    
                        style={!smallScreen ? {
                            fontSize: "10px",
                            height : "40px"
                        } :{}}
                        onClick={handleAddUserClick}>+ Add Users</button>
                </div>
               
                    
            </div>
            <div>
                <SearchBar
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
            </div>
         
            <div className='contactNavbar'>
                <div className='contactItem' style={smallScreen ? {} : {}}>Email id</div>
                <div className='contactItem'>Role</div>
                <div className='contactItem'>Password</div>
                <div className='contactItem'>Time of Contact</div>
                {smallScreen && <div className='contactItem'>Account Status</div>}
                {smallScreen && <div className='contactItem buttons'>Modify</div>}
            </div>
            <div
                className='contactsList' style={
               
                        {
                        height: '70%', overflowY: 'scroll',
                            
                        }
                    }
            >
                {currentContacts.map((e, index) => (
                    <div key={index} className={index % 2 !== 0 ? "changeBackgroundColor" : ""}>
                        <div className='contactDetail'>
                            <Tooltip title={e?.email}>
                                <div className='contactItem'>
                                    {e?.email.length > 12 ? `${e?.email.substring(0, 12)}...` : e?.email}
                                </div>
                            </Tooltip>

                            <Tooltip >
                                <div className='contactItem'>
                                    {
                                        e?.role === 'Student' ? "Guest" : "Host"
                                    }
                                    {/* {e?.role.length > 10 ? `${e?.role.substring(0, 10)}...` : e?.role} */}
                                </div>
                            </Tooltip>
                            <Tooltip title={e?.password}>
                                <div className='contactItem'>
                                    {e?.password.length > 10 ? `${e?.password.substring(0, 10)}...` : e?.password}
                                </div>
                            </Tooltip>
                            <div className='contactItem'>
                                {new Date(e?.createdAt).toLocaleString()}
                            </div>
                            {smallScreen && (
                                <div className='contactItem'>
                                    {isLoading && <Spin
                                        style={{
                                            position: 'fixed',
                                            top: "50%",
                                            left: "50%",
                                            color: 'red',
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                        spinning={isLoading}
                                        size="large"
                                    ></Spin>}
                                    <select
                                        onChange={(el) => changeStatus(el.target.value, e._id)}
                                        className={e?.status === 'active' ? "selectOptionPending" : "selectOptionActive"}
                                        value={e?.status}>
                                        <option value="pending">Pending</option>
                                        <option value="active">Active</option>
                                    </select>
                                </div>
                            )}
                            {smallScreen && (
                                <div className='contactItem buttons'>
                                    <button className="editButton" onClick={() => handleEditClick(e)}>Edit</button>
                                    <button className='deleteButton' onClick={() => handleDeleteClick(e)}>Delete</button>
                                </div>
                            )}
                        </div>
                        <div className='contactDetail buttonsEdit'>
                            {!smallScreen && (
                                <div className='contactItemDetails buttons'>
                                    <div className='contactItem'>
                                        {isLoading && <Spin
                                            style={{
                                                position: 'fixed',
                                                top: "50%",
                                                left: "50%",
                                                color: 'red',
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            spinning={isLoading}
                                            size="large"
                                        ></Spin>}
                                        <select
                                            
                                            onChange={(el) => changeStatus(el.target.value, e._id)}
                                            className={e?.status === 'active' ? "selectOptionPending" : "selectOptionActive"}
                                            value={e?.status}>
                                            <option value="pending">Pending</option>
                                            <option value="active">Active</option>
                                        </select>
                                    </div>
                                    <div className='buttonSmallScreen'>
                                        <button className="editButton" onClick={() => handleEditClick(e)}>Edit</button>
                                        <button className='deleteButton' onClick={() => handleDeleteClick(e)}>Delete</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <div className='adminPagination'>



                
                <div className='paginationDetail'>
                    Showing {indexOfFirstContact + 1} to {Math.min(indexOfLastContact, filteredUsers.length)} out of {filteredUsers.length} entries
                </div>

                <div
                    
                    className="paginationButtons"
                >
                    <Pagination
                        current={currentPage}
                        total={filteredUsers.length}
                        pageSize={itemsPerPage}
                        onChange={handlePageChange}
                    />
                </div>
                </div>
            </div>
            <Modal
                title="Edit User"
                visible={isEditModalVisible}
                onCancel={handleModalCancel}
                footer={null}
            >
                <EditUser setFormData={setFormData} formData={formData} onSave={handleEditUser} onCancel={handleModalCancel} />
            </Modal>
            <Modal
                title="Confirm Delete"
                visible={isDeleteModalVisible}
                onOk={handleDeleteUser}
                onCancel={handleModalCancel}
            >
                Are you sure you want to delete this user?
            </Modal>
            <Modal
                title="Add User"
                visible={isAddUserModalVisible}
                onCancel={handleModalCancel}
                footer={null}
            >
                <CreateUser />
            </Modal>
        </div>
    );
};

export default ContactDetails;
