import { Modal } from 'antd'; // assuming Modal is imported from antd
import React, { useEffect, useState } from 'react';
import { ImSwitch } from 'react-icons/im';
import Logo from '../../assets/Lumate-Logo-lt 1.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadProfile, logout } from '../../redux/actions/User';
import './Navbar.css';

const Navbar = () => {
    const navigate = useNavigate();
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [logoutModalVisible, setLogoutModalVisible] = useState(false);
    const [smallScreen, setSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {

            if (window.innerWidth <= 1168) {
                setSmallScreen(false);
            } else {
                setSmallScreen(true);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);


        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 

    const logoutt = async() => {
       await logout();
        await dispatch(loadProfile());
        setLogoutModalVisible(false);
        navigate('/signin')
        
    };

    return (
        <div className="Navbar">
            <div className="NavbarLeft">
                <img src={Logo} alt="Logo" />
                {/* <CiMenuBurger className="menuIcon" /> */}
              
            </div>
            <div className="NavbarRight">
                {
                    smallScreen && 

                <div className="switch">
                    <ImSwitch onClick={() => setLogoutModalVisible(true)} className="imSwitch" />
                </div>
                }
                {
                    smallScreen  &&
                <div>
                    <h3>{user?.name} </h3>
                    <p>{user?.email}</p>
                </div>
                }
            </div>
           
            <Modal
                title="Logout"
                visible={logoutModalVisible}
                onOk={logoutt}
                onCancel={() => setLogoutModalVisible(false)}
                okText="Logout"
                cancelText="Cancel"
            >
                <p>Are you sure you want to logout?</p>
            </Modal>
        </div>
    );
};

export default Navbar;
