import React from 'react'
import Navbar from '../../components/Navbar/Navbar'
import SideBar from '../../components/SideBar/SideBar'
import ContactDetails from '../../components/ContactDetails/ContactDetails'
import './AdminPanel.css'
import Sider from 'antd/es/layout/Sider'

const AdminPanel = () => {
  return (
    <div>
      <Navbar />
      <div className='AdminPanelDetailsPanel'>
        <SideBar />
      </div>
    </div>
  )
}

export default AdminPanel