import React, { useState } from 'react';
import logo from '../../assets/Lumate-Logo-lt 1.svg';

import { Row, Col, message, Button } from 'antd';
import { useParams } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import './ChangePassword.css';
import {  adminResetPassword } from '../../redux/actions/User';


const AdminForgottonPasswordLink = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const changePassword = async () => {
        if (newPassword !== confirmPassword) {
            message.error("Passwords do not match");
            return;
        }

        if (!newPassword || !confirmPassword) {
            message.error("Please fill password and confirm passsword");
            return;
        }

        if (newPassword.length < 6) {
            message.error("Password must be at least 6 characters long");
            return;
        }

        setLoading(true);

        try {
            const res = await adminResetPassword(newPassword,id)
            if (res?.success) {
                
                navigate('/passwordresetdone');
            } else {
                
                navigate('/passwordfailed');
            }

        } catch (error) {
            console.log("the error is ", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Row className='container'>
            <Col xs={22} sm={18} md={15} lg={12} xl={10} className='innerContainer'>
                <div className='innerBox'>
                    <div className="logo">
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className='changePassword'>
                        <h3>Change Password</h3>
                        <p>Please enter your new password</p>
                    </div>
                    <div className='changePasswordInput'>
                        <div>
                            <p>New Password</p>
                            <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        <div>
                            <p>Confirm New Password</p>
                            <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                        </div>
                    </div>
                    <Button className='button' onClick={changePassword} loading={loading}>
                        Reset Password
                    </Button>
                </div>
            </Col>
        </Row>
    );
}

export default AdminForgottonPasswordLink;
